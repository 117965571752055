import { useEffect, useState } from "react";
//redux
import { useSelector } from "react-redux";
import {
  addAction,
  getByIdAction,
  updateAction,
  selectContainer,
  clearAContainerAction,
  updateImagesAction,
  updateImagesAfterRepairAction,
  updateImagesGateOutAction,
  getContainerGateInAction,
  clearContainersAction,
  getContainersGateInWithoutLimitAction,
} from "../../../redux/slices/Container/containerSlice";
import { openForm, closeForm } from "../../../redux/slices/formSlices";
import {
  getAllNotificationAction,
  selectUser,
} from "../../../redux/slices/User/UserSlice";
import {
  clearSelectionAction,
  selectSelects,
} from "../../../redux/slices/SelectCheckbox/SelectCheckboxSlice";
import {
  selectServiceSuppliers,
  getAllWithoutLimitAction as getAllServiceSupplier,
} from "../../../redux/slices/ServiceSupplier/ServiceSupplierSlice";
//types
import { ContainerApiParams, ContainerApiProps } from "../../../types/apiType";
import { ImageApiProps } from "../../../types";
//icons
//libraries
import { Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import useSocketJoinRoom from "../../../hooks/useSocketJoinRoom";
//components
import { Socket } from "socket.io-client";
import ToastSuccess from "../../../components/Toast/success";
import ToastError from "../../../components/Toast/error";
import LogoutConfirmation from "../../../components/Logout/LogoutConfirmation";
import { Slider } from "../../../components/Slider";
//hooks
import { useAppDispatch } from "../../../hooks/reduxHooks";
//utils
import {
  convertDateDDMMYYYY,
  dateTimeGateInOutHelper,
  dateTimeSearchHelper,
  dateTimeSearchStartOfWeekHelper,
  nowDateDashHelper,
  nowDateHelper,
} from "../../../utils/DateHelper";
//files linked
import useSocketEventHandler from "../../../hooks/useSocketHandler";
import { FormUpdateImages } from "../../Container/FormUpdate/FormUpdateImages";
import { Form } from "../../Container/FormUpdate/Form";
import { FormUpdateImageAfterRepair } from "../../Container/FormUpdate/FormUpdateImageAfterRepair";
import { FormUpdateImagesGateOut } from "../../Container/FormUpdate/FormUpdateImagesGateOut";
import { CSVLink } from "react-csv";
import { ExportDataGateInFormatter } from "../../../utils/ExportDataFormatter";
import { updateMessageNotification } from "../../../constants/messageNotification";
import { paramsNotification } from "../../../utils/paramsHelper";
import LoadingComponent from "../../../components/LoadingComponent";
import { TableDepotAdmin } from "./tableDepotAdmin";
import { Search } from "./Search";
const ExcelJS = require("exceljs");
interface GateInProps {
  socket: Socket;
}
export const GateIn = ({ socket }: GateInProps) => {
  useSocketJoinRoom(socket, "container");
  const [contStatus, setContStatus] = useState(5);
  const [limit, setLimit] = useState(5);
  const [startDate, setStartDate] = useState(dateTimeSearchStartOfWeekHelper());
  const [endDate, setEndDate] = useState(dateTimeSearchHelper());
  const today = new Date();

  const handleAddContainerFromInvoice = (msg: any) => {
    dispatch(getContainerGateInAction(params));
  };

  const handleUpdate = (msg: any) => {
    dispatch(getContainerGateInAction(params));
    dispatch(getAllNotificationAction(paramsNotification));
  };

  // Handle "add-receive" and "update-receive" events with event handlers
  // useSocketEventHandler(socket, "add-receive", handleAdd);
  useSocketEventHandler(socket, "update-receive", handleUpdate);
  useSocketEventHandler(socket, "add-receive", handleAddContainerFromInvoice);
  //redux
  const dispatch = useAppDispatch();
  //navigation
  const navigate = useNavigate();

  const [formStatusState, setFormStatusState] = useState(false);
  const title = "Quản lý Gate In";
  const [isUpdate, setIsUpdate] = useState(false);
  const [slideStatusState, setSlideStatusState] = useState(false);
  const [isUpdateImages, setIsUpdateImages] = useState(false);
  const [isUpdateImagesAfterRepair, setIsUpdateImagesAfterRepair] =
    useState(false);
  const [isUpdateImagesGateOut, setIsUpdateImagesGateOut] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [reportGateIn, setReportGateIn] = useState<Array<ContainerApiProps>>(
    []
  );
  const [reportGateInOut, setReportGateInOut] = useState<
    Array<ContainerApiProps>
  >([]);
  const [reportGateOut, setReportGateOut] = useState<Array<ContainerApiProps>>(
    []
  );
  const [currentNameField, setCurrentNameField] = useState("");
  const [dataGateInDayShift, setDataGateInDayShift] = useState<
    Array<ContainerApiProps>
  >([]);
  const [dataGateInNightShift, setDataGateInNightShift] = useState<
    Array<ContainerApiProps>
  >([]);
  //set offset
  let offset = currentPage - 1;

  const [params] = useState<ContainerApiParams>({
    keyword: keyword,
    offset: offset,
    limit: limit,
    // contStatus: contStatus,
    startDate,
    endDate,
  });

  const getData = () => {
    document.title = title;
    // dispatch(getContainerGateInAction(params));
    dispatch(getContainersGateInWithoutLimitAction(params));
  };

  //get data from redux
  const containerData = useSelector(selectContainer);
  const {
    dataInDepot,
    loading,
    loadingImagesGateIn,
    loadingImagesNMR,
    loadingImagesGateOut,
    totalPageInDepot,
    serverError,
    container,
    appError,
    loadingGateIn,
    dataGateIn,
    dataGateInAllByDate,
  } = containerData;
  const userData = useSelector(selectUser);
  const { userAuth } = userData;
  const serviceSupplierData = useSelector(selectServiceSuppliers);
  const { data: dataServiceSupplier } = serviceSupplierData;
  const getSelects = useSelector(selectSelects);
  const { selected } = getSelects;

  useEffect(() => {
    dispatch(clearContainersAction());
    getData();
    dispatch(clearSelectionAction());
    dispatch(getAllNotificationAction(paramsNotification));
    const params1 = {
      keyword: "",
      offset: 0,
      limit: 100,
    };
    dispatch(getAllServiceSupplier(params1));
    // handleExportExcelGateIn();
    // handleExportExcelGateInOut();
  }, []);

  useEffect(() => {
    setReportGateIn(selected);
  }, [selected]);

  useEffect(() => {
    if (dataGateIn) {
      const findDataGateInDayShift = dataGateIn.filter((item) => {
        const dateGateInStr = item.listLifecycle?.at(-1)?.dateGateIn;

        if (!dateGateInStr) return false;

        const dateGateIn = new Date(dateGateInStr);

        const now = new Date();
        const startOfDayShift = new Date(now);
        startOfDayShift.setHours(7, 0, 0, 0); // Set time to 7:00 a.m.

        const endOfDayShift = new Date(now);
        endOfDayShift.setHours(17, 0, 0, 0); // Set time to 5:00 p.m.

        // Check if dateGateIn falls within the shift hours
        return dateGateIn >= startOfDayShift && dateGateIn <= endOfDayShift;
      });

      setDataGateInDayShift(findDataGateInDayShift);
    }
  }, [dataGateIn]);

  useEffect(() => {
    if (dataGateIn) {
      const findDataGateInNightShift = dataGateIn.filter((item) => {
        const dateGateInStr = item.listLifecycle?.at(-1)?.dateGateIn;

        if (!dateGateInStr) return false;

        const dateGateIn = new Date(dateGateInStr);

        const today = new Date();

        // Set the start of the night shift (5:00 p.m. yesterday)
        const startOfNightShift = new Date(today);
        startOfNightShift.setDate(startOfNightShift.getDate() - 1); // Move to yesterday
        startOfNightShift.setHours(17, 0, 0, 0);

        // Set the end of the night shift (7:00 a.m. today)
        const endOfNightShift = new Date(today);
        endOfNightShift.setHours(7, 0, 0, 0);
        // Check if dateGateIn is within the range of 5 p.m. yesterday to 7 a.m. today
        return dateGateIn >= startOfNightShift && dateGateIn <= endOfNightShift;
      });

      setDataGateInNightShift(findDataGateInNightShift);
    }
  }, [dataGateIn]);

  //end get data
  // ==== paging ==== //
  const handlePageClick = (event: { selected: number }) => {
    params.offset = event.selected * limit;
    getData();
  };
  // ==== paging END ==== //
  // search data
  const handleSearch = (
    // keyword: string,
    // contStatus: number,
    // numberOfLimit: number,
    startDate: string,
    endDate: string
  ) => {
    // params.keyword = keyword;
    // params.contStatus = contStatus;
    // params.limit = numberOfLimit;
    params.startDate = startDate;
    params.endDate = endDate;
    setStartDate(startDate);
    setEndDate(endDate);
    // setKeyword(keyword);
    // setContStatus(contStatus);
    // setLimit(numberOfLimit);
    params.offset = 0;

    getData();
    // handleExportExcelGateIn();
    // handleExportExcelGateInOut();
    setSearchKey((prevKey) => prevKey + 1);
  };

  // open create form event
  const handleOpenFormAdd = () => {
    setFormStatusState(true);
    const action = openForm();
    dispatch(action);
  };

  // create data event
  const handleAddData = async (data: ContainerApiProps) => {
    setFormStatusState(false);

    const action = await dispatch(addAction(data));

    if (addAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: "ok",
      });
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  // update data event
  const handleUpdateData = async (
    _id: string | undefined,
    data: ContainerApiProps
  ) => {
    setFormStatusState(false);
    setIsUpdate(false);
    const dataUpdate = {
      _id,
      ...data,
    };
    const action = await dispatch(updateAction(dataUpdate));

    if (updateAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: "ok",
      });
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  // open update form event
  const handleOpenFormUpdate = (id: string) => {
    setFormStatusState(true);
    const action = openForm();
    dispatch(action);
    setIsUpdate(true);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleOpenSlide = (id: string, currentNameField: string) => {
    setSlideStatusState(true);
    const action = openForm();
    dispatch(action);
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
    // Set the currentNameField to the selected nameField
    setCurrentNameField(currentNameField);
  };

  // close form event
  const handleCloseForm = () => {
    setFormStatusState(false);
    const action = closeForm();
    dispatch(action);
    setIsUpdate(false);
  };

  // close form slide event
  const handleCloseSlide = () => {
    setSlideStatusState(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleOpenFormUpdateImages = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImages(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleCloseUpdateImages = () => {
    setIsUpdateImages(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleOpenFormUpdateImagesAfterRepair = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImagesAfterRepair(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleOpenFormUpdateImagesGateOut = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImagesGateOut(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleCloseUpdateImagesAfterRepair = () => {
    setIsUpdateImagesAfterRepair(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleCloseUpdateImagesGateOut = () => {
    setIsUpdateImagesGateOut(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleUpdateImagesData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImages(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesAction(dataUpdate));

    if (updateImagesAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật hình ảnh container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateImagesAfterRepairData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImagesAfterRepair(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesAfterRepairAction(dataUpdate));

    if (updateImagesAfterRepairAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật hình ảnh sau sửa chữa container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateImagesGateOutData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImagesGateOut(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesGateOutAction(dataUpdate));

    if (updateImagesGateOutAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật hình ảnh gate out container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  // check show form
  const displayForm = () => {
    if (formStatusState) {
      return (
        <Form
          closeForm={handleCloseForm}
          isUpdate={isUpdate}
          addData={handleAddData}
          updateData={handleUpdateData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImages) {
      return (
        <FormUpdateImages
          closeForm={handleCloseUpdateImages}
          updateData={handleUpdateImagesData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImagesAfterRepair) {
      return (
        <FormUpdateImageAfterRepair
          closeForm={handleCloseUpdateImagesAfterRepair}
          updateData={handleUpdateImagesAfterRepairData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImagesGateOut) {
      return (
        <FormUpdateImagesGateOut
          closeForm={handleCloseUpdateImagesGateOut}
          updateData={handleUpdateImagesGateOutData}
          dataUpdate={container}
        />
      );
    }
  };

  if (
    appError?.statusCode === 500 &&
    appError.message?.includes("Token đã hết hạn")
  ) {
    LogoutConfirmation(
      navigate,
      dispatch,
      "Đã hết phiên làm việc, vui lòng đăng nhập lại?"
    );
    // Return a placeholder or loading component, for example
    return <div>Loading...</div>;
  }

  const toDataURL = (url: any) => {
    const promise = new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.readAsDataURL(xhr.response);
        reader.onloadend = function () {
          resolve({ base64Url: reader.result });
        };
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });

    return promise;
  };

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Gate In");
    sheet.properties.defaultRowHeight = 15;
    sheet.autoFilter = "A5:O5";
    // Merge cells for the title on the second row
    sheet.mergeCells("A1:O2");

    // Set custom title in the merged cell (A1)
    const customCell = sheet.getCell("A1");
    customCell.font = {
      name: "Times New Roman",
      family: 4,
      size: 20,
      underline: true,
      bold: true,
    };
    customCell.alignment = { vertical: "middle", horizontal: "center" }; // Center align the title
    // customCell.value = `Báo Cáo Gate In Ngày ${nowDateDashHelper(today)}`; // Custom title
    if (params.startDate !== undefined) {
      customCell.value = `Báo Cáo Gate In Ngày ${
        params.startDate === params.endDate
          ? convertDateDDMMYYYY(params.endDate)
          : `${convertDateDDMMYYYY(params.startDate)} đến ${convertDateDDMMYYYY(
              params.endDate
            )}`
      }`; // Custom title
    }

    // Set font and fill for the header row (Row 4)
    sheet.getRow(3).font = {
      name: "Times New Roman",
      family: 4,
      size: 11,
      bold: true,
    };

    sheet.getRow(4).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFF2F2F2" }, // Corrected color format
    };

    // Define headers and columns
    sheet.columns = [
      {
        key: "stt",
        width: 8,
      },
      {
        key: "lineOperation",
        width: 10,
      },
      {
        key: "containerNumber",
        width: 18,
      },
      {
        key: "size",
        width: 10,
      },
      {
        key: "yearOfManufacture",
        width: 10,
      },
      {
        key: "brandOfUnit",
        width: 10,
      },
      {
        key: "noteGateIn",
        width: 10,
      },
      {
        key: "containerCondition",
        width: 25,
      },
      {
        key: "bookingNumber",
        width: 15,
      },
      {
        key: "trunkNumberGateIn",
        width: 15,
      },
      {
        key: "dateGateIn",
        width: 23,
      },
      {
        key: "receiver",
        width: 15,
      },
      {
        key: "from",
        width: 15,
      },
      {
        key: "to",
        width: 10,
      },
      {
        key: "storageNumber",
        width: 15,
      },
      {
        key: "thumbnail",
        width: 30,
      },
    ];

    const headerData = [
      "NO.",
      "LINE",
      "CONT NO.",
      "TYPE",
      "YOM",
      "Machine",
      "Grade",
      "CONDITION",
      "Số Booking in",
      "Xe Gate In",
      "Giờ Gate In",
      "Người nhận",
      "FROM",
      "TO",
      "Ngày lưu bãi",
    ];

    // Add header row using an array (this will map to column positions)
    const headerRow = sheet.addRow(headerData);

    // Apply styles to each cell in the header row
    headerRow.eachCell((cell: any) => {
      cell.font = {
        name: "Times New Roman",
        family: 4,
        size: 14,
        bold: true,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        // wrapText: true,
      }; // Optional: Center align the header
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFF2F2F2" }, // Optional: Light gray background color
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      }; // Optional: Border around cells
    });

    // Insert data rows
    dataGateIn?.forEach((product, index) => {
      const rowNumber = index + 5; // Data starts from row 5, as header is on row 4
      const rowData = {
        stt: index + 1,
        lineOperation: product?.lineOperation?.label ?? " ",
        containerNumber: product?.containerNumber ?? "",
        size: product.size,
        yearOfManufacture: product.yearOfManufacture,
        brandOfUnit: product.brandOfUnit?.label ?? " ",
        noteGateIn: product.listLifecycle?.at(-1)?.noteGateIn,
        containerCondition: product?.containerCondition,
        bookingNumber: product?.bookingNumber ?? " ",
        trunkNumberGateIn: product.listLifecycle?.at(-1)?.trunkNumberGateIn,
        dateGateIn: product.listLifecycle?.at(-1)?.dateGateIn
          ? dateTimeGateInOutHelper(product.listLifecycle?.at(-1)?.dateGateIn)
          : "",
        receiver: product.listLifecycle?.at(-1)?.receiver,
        from:
          product.contStatus === 8
            ? "Nhận từ khách"
            : product.listLifecycle?.at(-1)?.from,
        to:
          product.contStatus === 8
            ? product.serviceSupplier?.label
            : product.listLifecycle?.at(-1)?.to ?? " ",
        storageNumber: product.storageNumber,
      };

      const dataStyle = sheet.addRow(rowData);

      dataStyle.eachCell((cell: any) => {
        cell.font = {
          name: "Times New Roman",
          family: 4,
          size: 11,
          // bold: true,
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "left",
          // wrapText: true,
        }; // Optional: Center align the header
        // cell.fill = {
        //   type: "pattern",
        //   pattern: "solid",
        //   fgColor: { argb: "FFF2F2F2" }, // Optional: Light gray background color
        // };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        }; // Optional: Border around cells
      });
    });

    // Optionally add image if needed
    // (Your image insertion code would follow here)

    // Save the file
    workbook.xlsx.writeBuffer().then(function (data: any) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      // anchor.download = "Báo_Cáo_Gate_In.xlsx";
      anchor.download = `Báo_Cáo_Gate_In_${nowDateHelper(today)}`;

      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  const exportNightShiftExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Gate In");
    sheet.properties.defaultRowHeight = 15;
    sheet.autoFilter = "A5:O5";
    // Merge cells for the title on the second row
    sheet.mergeCells("A1:O2");

    // Set custom title in the merged cell (A1)
    const customCell = sheet.getCell("A1");
    customCell.font = {
      name: "Times New Roman",
      family: 4,
      size: 20,
      underline: true,
      bold: true,
    };
    customCell.alignment = { vertical: "middle", horizontal: "center" }; // Center align the title
    // customCell.value = `Báo Cáo Gate In Ngày ${nowDateDashHelper(today)}`; // Custom title
    const yesterdayGateIn = new Date(today);
    yesterdayGateIn.setDate(yesterdayGateIn.getDate() - 1); // Move to yesterday
    customCell.value = `Báo Cáo Gate In Ngày ${nowDateDashHelper(
      yesterdayGateIn
    )}`;
    // Set font and fill for the header row (Row 4)
    sheet.getRow(3).font = {
      name: "Times New Roman",
      family: 4,
      size: 11,
      bold: true,
    };

    sheet.getRow(4).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFF2F2F2" }, // Corrected color format
    };

    // Define headers and columns
    sheet.columns = [
      {
        key: "stt",
        width: 8,
      },
      {
        key: "lineOperation",
        width: 10,
      },
      {
        key: "containerNumber",
        width: 18,
      },
      {
        key: "size",
        width: 10,
      },
      {
        key: "yearOfManufacture",
        width: 10,
      },
      {
        key: "brandOfUnit",
        width: 10,
      },
      {
        key: "noteGateIn",
        width: 10,
      },
      {
        key: "containerCondition",
        width: 25,
      },
      {
        key: "bookingNumber",
        width: 15,
      },
      {
        key: "trunkNumberGateIn",
        width: 15,
      },
      {
        key: "dateGateIn",
        width: 23,
      },
      {
        key: "receiver",
        width: 15,
      },
      {
        key: "from",
        width: 15,
      },
      {
        key: "to",
        width: 10,
      },
      {
        key: "storageNumber",
        width: 15,
      },
      {
        key: "thumbnail",
        width: 30,
      },
    ];

    const headerData = [
      "NO.",
      "LINE",
      "CONT NO.",
      "TYPE",
      "YOM",
      "Machine",
      "Grade",
      "CONDITION",
      "Số Booking in",
      "Xe Gate In",
      "Giờ Gate In",
      "Người nhận",
      "FROM",
      "TO",
      "Ngày lưu bãi",
    ];

    // Add header row using an array (this will map to column positions)
    const headerRow = sheet.addRow(headerData);

    // Apply styles to each cell in the header row
    headerRow.eachCell((cell: any) => {
      cell.font = {
        name: "Times New Roman",
        family: 4,
        size: 14,
        bold: true,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        // wrapText: true,
      }; // Optional: Center align the header
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFF2F2F2" }, // Optional: Light gray background color
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      }; // Optional: Border around cells
    });

    // Insert data rows
    dataGateInNightShift?.forEach((product, index) => {
      const rowNumber = index + 5; // Data starts from row 5, as header is on row 4
      const rowData = {
        stt: index + 1,
        lineOperation: product?.lineOperation?.label ?? " ",
        containerNumber: product?.containerNumber ?? "",
        size: product.size,
        yearOfManufacture: product.yearOfManufacture,
        brandOfUnit: product.brandOfUnit?.label ?? " ",
        noteGateIn: product.listLifecycle?.at(-1)?.noteGateIn,
        containerCondition: product?.containerCondition,
        bookingNumber: product?.bookingNumber ?? " ",
        trunkNumberGateIn: product.listLifecycle?.at(-1)?.trunkNumberGateIn,
        dateGateIn: product.listLifecycle?.at(-1)?.dateGateIn
          ? dateTimeGateInOutHelper(product.listLifecycle?.at(-1)?.dateGateIn)
          : "",
        receiver: product.listLifecycle?.at(-1)?.receiver,
        from:
          product.contStatus === 8
            ? "Nhận từ khách"
            : product.listLifecycle?.at(-1)?.from,
        to:
          product.contStatus === 8
            ? product.serviceSupplier?.label
            : product.listLifecycle?.at(-1)?.to ?? " ",
        storageNumber: product.storageNumber,
      };

      const dataStyle = sheet.addRow(rowData);

      dataStyle.eachCell((cell: any) => {
        cell.font = {
          name: "Times New Roman",
          family: 4,
          size: 11,
          // bold: true,
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "left",
          // wrapText: true,
        }; // Optional: Center align the header
        // cell.fill = {
        //   type: "pattern",
        //   pattern: "solid",
        //   fgColor: { argb: "FFF2F2F2" }, // Optional: Light gray background color
        // };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        }; // Optional: Border around cells
      });
    });

    // Optionally add image if needed
    // (Your image insertion code would follow here)

    // Save the file
    workbook.xlsx.writeBuffer().then(function (data: any) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      // anchor.download = "Báo_Cáo_Gate_In.xlsx";
      anchor.download = `Báo_Cáo_Gate_In_${nowDateHelper(today)}_Ca_Tối`;

      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  const exportDayShiftExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Gate In");
    sheet.properties.defaultRowHeight = 15;
    sheet.autoFilter = "A5:O5";
    // Merge cells for the title on the second row
    sheet.mergeCells("A1:O2");

    // Set custom title in the merged cell (A1)
    const customCell = sheet.getCell("A1");
    customCell.font = {
      name: "Times New Roman",
      family: 4,
      size: 20,
      underline: true,
      bold: true,
    };
    customCell.alignment = { vertical: "middle", horizontal: "center" }; // Center align the title
    // customCell.value = `Báo Cáo Gate In Ngày ${nowDateDashHelper(today)}`; // Custom title
    customCell.value = `Báo Cáo Gate In Ngày ${nowDateDashHelper(new Date())}`;

    // Set font and fill for the header row (Row 4)
    sheet.getRow(3).font = {
      name: "Times New Roman",
      family: 4,
      size: 11,
      bold: true,
    };

    sheet.getRow(4).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFF2F2F2" }, // Corrected color format
    };

    // Define headers and columns
    sheet.columns = [
      {
        key: "stt",
        width: 8,
      },
      {
        key: "lineOperation",
        width: 10,
      },
      {
        key: "containerNumber",
        width: 18,
      },
      {
        key: "size",
        width: 10,
      },
      {
        key: "yearOfManufacture",
        width: 10,
      },
      {
        key: "brandOfUnit",
        width: 10,
      },
      {
        key: "noteGateIn",
        width: 10,
      },
      {
        key: "containerCondition",
        width: 25,
      },
      {
        key: "bookingNumber",
        width: 15,
      },
      {
        key: "trunkNumberGateIn",
        width: 15,
      },
      {
        key: "dateGateIn",
        width: 23,
      },
      {
        key: "receiver",
        width: 15,
      },
      {
        key: "from",
        width: 15,
      },
      {
        key: "to",
        width: 10,
      },
      {
        key: "storageNumber",
        width: 15,
      },
      {
        key: "thumbnail",
        width: 30,
      },
    ];

    const headerData = [
      "NO.",
      "LINE",
      "CONT NO.",
      "TYPE",
      "YOM",
      "Machine",
      "Grade",
      "CONDITION",
      "Số Booking in",
      "Xe Gate In",
      "Giờ Gate In",
      "Người nhận",
      "FROM",
      "TO",
      "Ngày lưu bãi",
    ];

    // Add header row using an array (this will map to column positions)
    const headerRow = sheet.addRow(headerData);

    // Apply styles to each cell in the header row
    headerRow.eachCell((cell: any) => {
      cell.font = {
        name: "Times New Roman",
        family: 4,
        size: 14,
        bold: true,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        // wrapText: true,
      }; // Optional: Center align the header
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFF2F2F2" }, // Optional: Light gray background color
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      }; // Optional: Border around cells
    });

    // Insert data rows
    dataGateInDayShift?.forEach((product, index) => {
      const rowNumber = index + 5; // Data starts from row 5, as header is on row 4
      const rowData = {
        stt: index + 1,
        lineOperation: product?.lineOperation?.label ?? " ",
        containerNumber: product?.containerNumber ?? "",
        size: product.size,
        yearOfManufacture: product.yearOfManufacture,
        brandOfUnit: product.brandOfUnit?.label ?? " ",
        noteGateIn: product.listLifecycle?.at(-1)?.noteGateIn,
        containerCondition: product?.containerCondition,
        bookingNumber: product?.bookingNumber ?? " ",
        trunkNumberGateIn: product.listLifecycle?.at(-1)?.trunkNumberGateIn,
        dateGateIn: product.listLifecycle?.at(-1)?.dateGateIn
          ? dateTimeGateInOutHelper(product.listLifecycle?.at(-1)?.dateGateIn)
          : "",
        receiver: product.listLifecycle?.at(-1)?.receiver,
        from:
          product.contStatus === 8
            ? "Nhận từ khách"
            : product.listLifecycle?.at(-1)?.from,
        to:
          product.contStatus === 8
            ? product.serviceSupplier?.label
            : product.listLifecycle?.at(-1)?.to ?? " ",
        storageNumber: product.storageNumber,
      };

      const dataStyle = sheet.addRow(rowData);

      dataStyle.eachCell((cell: any) => {
        cell.font = {
          name: "Times New Roman",
          family: 4,
          size: 11,
          // bold: true,
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "left",
          // wrapText: true,
        }; // Optional: Center align the header
        // cell.fill = {
        //   type: "pattern",
        //   pattern: "solid",
        //   fgColor: { argb: "FFF2F2F2" }, // Optional: Light gray background color
        // };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        }; // Optional: Border around cells
      });
    });

    // Optionally add image if needed
    // (Your image insertion code would follow here)

    // Save the file
    workbook.xlsx.writeBuffer().then(function (data: any) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      // anchor.download = "Báo_Cáo_Gate_In.xlsx";
      anchor.download = `Báo_Cáo_Gate_In_${nowDateHelper(today)}_Ca_Sáng`;

      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <>
      <div className="bg-blue-100 h-screen ">
        <Transition
          show={formStatusState}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {displayForm()}
        </Transition>
        <Transition
          show={isUpdateImages}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {displayForm()}
        </Transition>
        <Transition
          show={isUpdateImagesAfterRepair}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {displayForm()}
        </Transition>
        <Transition
          show={isUpdateImagesGateOut}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {displayForm()}
        </Transition>
        {slideStatusState && (
          <div className="z-10 max-w-[540px] h-[620px] m-auto py-16 px-4 group w-3/4 fixed left-1/2 ml-[-37.5%] min-[2000px]:ml-[-30%] top-16">
            <Slider
              closeForm={handleCloseSlide}
              isBigger={false}
              currentNameField={currentNameField}
            />
          </div>
        )}
        <div className="flex flex-col bg-slate-50 mx-2 rounded-2xl p-4 ">
          <div className="flex flex-row ml-2 justify-between overflow-hidden overflow-x-auto">
            <div className="absolute left-5 w-1 bg-blue-400 h-14"></div>
            <div className="flex-none flex-shrink-0">
              <p className="font-sans font-semibold text-3xl">{title}</p>
              <p className="font-sans text-sm italic">{title} Conta</p>
            </div>

            <div className="flex ">
              <div className="flex items-center grow justify-end flex-shrink-0">
                {loadingGateIn ? (
                  <div className="text-center pl-4 pt-4">
                    <LoadingComponent />
                  </div>
                ) : (
                  <button
                    className="ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                    onClick={exportExcelFile}
                  >
                    Xuất excel Gate In
                  </button>
                )}
              </div>
              <div className="flex items-center grow justify-end flex-shrink-0">
                {loadingGateIn ? (
                  <div className="text-center pl-4 pt-4">
                    <LoadingComponent />
                  </div>
                ) : (
                  <button
                    className="ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                    onClick={exportNightShiftExcelFile}
                  >
                    Xuất excel Gate In Ca Tối Hôm Qua
                  </button>
                )}
              </div>
              <div className="flex items-center grow justify-end flex-shrink-0">
                {loadingGateIn ? (
                  <div className="text-center pl-4 pt-4">
                    <LoadingComponent />
                  </div>
                ) : (
                  <button
                    className="ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                    onClick={exportDayShiftExcelFile}
                  >
                    Xuất excel Gate In Ca Sáng Hôm Nay
                  </button>
                )}
              </div>

              {/* Gate in button */}
              <div className="flex items-center grow justify-end flex-shrink-0">
                {reportGateIn !== undefined && reportGateIn.length > 0 && (
                  <CSVLink
                    filename={`Gate In-${Date.now()}.csv`}
                    data={ExportDataGateInFormatter(reportGateIn)}
                    onClick={() => {
                      ToastSuccess("Tải về file Excel thành công!");
                    }}
                    className="ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                  >
                    <span>Xuất excel Gate In đã chọn</span>
                  </CSVLink>
                )}
              </div>
            </div>
          </div>

          {/* <Search handleSearch={handleSearch} socket={socket} />
          <div className="overflow-hidden">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200 table-auto mb-4">
                      <thead className="bg-gray-50">
                        <TableHeaderGateInOut
                          // titles={headersContainerGateInOutDepot}
                          titles={
                            userAuth?.role === 0
                              ? headersAdminContainerGateInOutDepot
                              : headersContainerGateInOutDepot
                          }
                          isCheckbox={true}
                        />
                      </thead>
                      <tbody>
                        {loadingGateIn ? (
                          <tr>
                            <td colSpan={4} className="text-center pl-4 pt-4">
                              <LoadingComponent />
                            </td>
                          </tr>
                        ) : (dataGateIn && dataGateIn?.length <= 0) ||
                          dataGateIn == null ? (
                          <tr>
                            <td colSpan={4} className="text-center pl-4 pt-4">
                              <NotFoundLoading />
                            </td>
                          </tr>
                        ) : (
                          <ListItem
                            containerUpdate={container}
                            loadingImagesGateIn={loadingImagesGateIn}
                            loadingImagesMNR={loadingImagesNMR}
                            loadingImagesGateOut={loadingImagesGateOut}
                            data={dataGateIn}
                            openFormUpdate={(id) => handleOpenFormUpdate(id)}
                            openFormUpdateImages={(id) =>
                              handleOpenFormUpdateImages(id)
                            }
                            openFormUpdateImagesAfterRepair={(id) =>
                              handleOpenFormUpdateImagesAfterRepair(id)
                            }
                            openFormUpdateImagesGateOut={(id) =>
                              handleOpenFormUpdateImagesGateOut(id)
                            }
                            socket={socket}
                            userAuth={userAuth}
                            openSlide={(imageId, currentNameField) =>
                              handleOpenSlide(imageId, currentNameField)
                            }
                          />
                        )}
                      </tbody>
                    </table>
                    {totalPageInDepot && totalPageInDepot > 1 ? (
                      <ReactPaginate
                        key={searchKey}
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={totalPageInDepot} // Replace with the total number of pages
                        previousLabel="<"
                        pageClassName="inline-block px-4 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        pageLinkClassName="block w-full h-full"
                        previousClassName="inline-block px-2 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        previousLinkClassName="block w-full h-full"
                        nextClassName="inline-block px-2 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        nextLinkClassName="block w-full h-full"
                        breakLabel="..."
                        breakClassName="inline-block px-2 py-1 mx-1 text-gray-600"
                        breakLinkClassName=""
                        containerClassName="flex justify-start my-4 "
                        activeClassName="background-color-base-paging text-white"
                        disabledClassName="opacity-50 cursor-not-allowed"
                        renderOnZeroPageCount={null}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <Search
            handleSearch={handleSearch}
            socket={socket}
            userAuth={userAuth}
          />
          {loadingGateIn && dataGateIn && dataGateIn?.length <= 0 ? (
            <div className="text-center pl-4 pt-4">
              <LoadingComponent />
            </div>
          ) : (
            <TableDepotAdmin
              dataContainer={dataGateIn}
              openFormUpdate={(id: string) => handleOpenFormUpdate(id)}
              containerUpdate={container}
              loadingImagesGateIn={loadingImagesGateIn}
              loadingImagesMNR={loadingImagesNMR}
              loadingImagesGateOut={loadingImagesGateOut}
              openFormUpdateImages={(id) => handleOpenFormUpdateImages(id)}
              openFormUpdateImagesAfterRepair={(id) =>
                handleOpenFormUpdateImagesAfterRepair(id)
              }
              openFormUpdateImagesGateOut={(id) =>
                handleOpenFormUpdateImagesGateOut(id)
              }
              socket={socket}
              userAuth={userAuth}
              openSlide={(imageId, currentNameField) =>
                handleOpenSlide(imageId, currentNameField)
              }
            />
          )}
        </div>
      </div>
    </>
  );
};
